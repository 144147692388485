import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { addDays, format, getISOWeek, isAfter, isBefore, isSameDay, parseISO, startOfWeek, subDays } from 'date-fns';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { StorageKeys } from '../../core/storage/storage.keys';
import { StorageService } from '../../core/storage/storage.service';
import { Routes } from './enum/routes';
import { IBoilerPlatesFormatted, IBoilerPlatesResponse } from './interfaces/boilerPlates.interface';
import { DeclinedHoursResponse } from './interfaces/declinedHours.interface';
import { ISpreadResponse } from './interfaces/spread.interface';
@Injectable({
  providedIn: 'root',
})
export class TimeRecordingService {
  public reloading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public restoreParamsBarChart: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public restoreParamsDonutChart: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public restoreParamsTable: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public restoreParamsTimeSheetTable: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public AddnewProject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public clearRedBordersEvent: EventEmitter<void> = new EventEmitter<void>();
  public highlightUnsavedItemsEvent: EventEmitter<void> = new EventEmitter<void>();
  public weekFilter: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public settings: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public skipSidebarClone = false;
  private httpOptions = {};

  private maxCacheAge = 5000;
  private getWeekDataCache$!: Observable<any>;
  private lastWeekDataCache = 0;
  private lastWeekDataCacheParams: any = [];
  private getMonthDataCache$!: Observable<any>;
  private lastMonthDataCache = 0;
  private lastMonthDataCacheParams: any = [];
  private timeRecordingSettingsSubject: BehaviorSubject<object | string> = new BehaviorSubject<object | string>({});

  constructor(public http: HttpClient, private storageService: StorageService, private translate: TranslateService) {
    this.storageService.setItem(StorageKeys.UNSAVEDCHANGES, false);
  }

  public loginUser(): number {
    const user = this.storageService.getItem(StorageKeys.USER);
    if (user) {
      return user.id;
    } else {
      return 0;
    }
  }

  public getDayData(date: string | null, employeeId: string | number | null) {
    if (isNaN(new Date(date).getTime()) === null || employeeId === null) {
      return;
    }

    const params = {
      dateFrom: date,
      employeeId,
    };
    return this.http.get(environment.url + Routes.TIMERECORDING, { params });
  }

  public getWeekData(
    type: number | null,
    employeeId: number | string | null,
    week: number = getISOWeek(new Date()),
    year = new Date().getFullYear(),
  ) {
    if (type === null || employeeId === null) {
      return;
    }

    const keys = [type, employeeId, week, year];

    const now = new Date().getTime();
    if (
      this.lastWeekDataCache + this.maxCacheAge < now ||
      !this.getWeekDataCache$ ||
      JSON.stringify(keys) !== JSON.stringify(this.lastWeekDataCacheParams)
    ) {
      this.getWeekDataCache$ = this.requestWeekData(type, employeeId, week, year).pipe(shareReplay(1));
      this.lastWeekDataCache = now;
      this.lastWeekDataCacheParams = keys;
    }

    return this.getWeekDataCache$;
  }

  private requestWeekData(type: number, employeeId: number | string, week: number, year: number) {
    const params = {
      periodType: type,
      employeeId,
      dateCW: week,
      dateY: year,
    };
    return this.http.get(environment.url + Routes.TIMERECORDING, { params });
  }

  public getMonthData(type: number | null, date: string | null, employeeId: string | number | null) {
    if (isNaN(new Date(date).getTime()) || type === null || employeeId === null) {
      return;
    }

    const keys = [type, date, employeeId];
    const now = new Date().getTime();

    if (
      this.lastMonthDataCache + this.maxCacheAge < now ||
      !this.getMonthDataCache$ ||
      JSON.stringify(keys) !== JSON.stringify(this.lastMonthDataCacheParams)
    ) {
      this.getMonthDataCache$ = this.requestMonthData(type, date, employeeId).pipe(shareReplay(1));
      this.lastMonthDataCache = now;
      this.lastMonthDataCacheParams = keys;
    }

    return this.getMonthDataCache$;
  }

  private requestMonthData(type: number, date: string, employeeId: number | string) {
    const params = {
      dateFrom: date,
      periodType: type,
      employeeId,
    };
    return this.http.get(environment.url + Routes.TIMERECORDING, { params });
  }

  public getTimeSheetData(type, teamId, dateFrom, dateTill, employeeId, passTeamId, page, limit) {
    const params: { [key: string]: any } = {
      employeeId,
      periodType: type,
      dateFrom,
      dateTill,
    };

    if (passTeamId) {
      params.teamId = teamId;
    }

    if (page && limit) {
      params.page = page;
      params.limit = limit;
    }

    return this.http.get(environment.url + Routes.TIMERECORDING, { params });
  }

  public getWorkingTimeLogData(employeeId, date) {
    if (employeeId && date) {
      const params = {
        date,
      };
      return this.http.get(environment.url + Routes.WORKINGTIMELOG + employeeId, { params });
    }
  }

  public createWorkingTimeLogData(employeeId: number, date: string, postJson: any) {
    if (employeeId && date && postJson) {
      const url = `${environment.url}${Routes.WORKINGTIMELOG}${employeeId}/${date}`;
      if (postJson.length > 0) {
        return this.http.post(url, postJson).pipe(
          catchError((error: HttpErrorResponse) => {
            return throwError(error.error);
          }),
        );
      } else {
        return this.deleteWorkingTimeLogData(employeeId, date);
      }
    }
  }

  public deleteWorkingTimeLogData(employeeId: number, date: string) {
    if (employeeId && date) {
      const url = `${environment.url}${Routes.WORKINGTIMELOG}${employeeId}/${date}`;
      return this.http.delete(url).pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error.error);
        }),
      );
    }
  }

  public getdateRangeData(type, dateFrom, dateTill, employeeId) {
    const params = {
      dateFrom,
      dateTill,
      periodType: type,
      employeeId,
    };
    return this.http.get(environment.url + Routes.TIMERECORDING, { params });
  }

  isMyProjectsVisible = false;
  addProjectUserid: number;
  public addProjectDate: Array<any>[];
  public toggleMyProjectsVisibility(projectUserid = 33, date = format(new Date(), 'yyyy-MM-dd')): void {
    this.addProjectDate = [];
    this.addProjectUserid = projectUserid;
    this.isMyProjectsVisible = !this.isMyProjectsVisible;

    let dateArray = [];
    if (date.includes(',')) {
      dateArray = date.split(', ');
    } else {
      dateArray = [date];
    }
    this.addProjectDate = dateArray;
  }

  public hideSideBar(): any {
    this.isMyProjectsVisible = false;
  }

  /* addProjectUserIdGet */
  public addProjectUserIdGet(): number {
    return this.addProjectUserid;
  }

  /* addProjectDate */
  public addProjectDateGet(): any {
    return this.addProjectDate;
  }

  isStopwatchVisible = false;
  public toggleStopwatchVisibility(): void {
    this.isStopwatchVisible = !this.isStopwatchVisible;
  }

  /* Project Time Log Get */
  public getProjectTimeLogData(
    employeeId,
    date,
    type,
    week: number = getISOWeek(new Date()),
    year = new Date().getFullYear(),
  ) {
    let url = '';
    switch (type) {
      case 'day':
        url = `${environment.url}${Routes.PROJECTTIMELOG}${type}/${date}?employeeId=${employeeId}`;
        break;
      case 'week':
        url = `${environment.url}${Routes.PROJECTTIMELOG}${type}/${employeeId}?dateCW=${week}&dateY=${year}`;
        break;
    }

    return this.http.get(url).pipe(
      map((response: any) => {
        const filteredFavorites = response.favorites?.reduce((acc, favorite) => {
          const existsInData = response.data.some((entry) => entry.cp.cpId === favorite.cp.cpId);

          if (!existsInData) {
            switch (type) {
              case 'day':
                if (!favorite.billing) {
                  favorite.billing = {
                    billingId: 0,
                    billingDate: '',
                    billingCreated: '',
                    billingQuantity: 0,
                    billingIsBillable: favorite.cp.cpIsBillable,
                    billingExternalComment: '',
                    billingInternalComment: '',
                  };
                  favorite.cp.cpNewAdded = true;
                }
                break;
              case 'week':
                if (!favorite.billingsData) {
                  favorite.billingsData = {
                    billingsSummary: {
                      quantityTotal: 0,
                      billableQuantityTotal: 0,
                      notBillableQuantityTotal: 0,
                    },
                    billings: [],
                  };
                  favorite.cp.cpNewAdded = true;
                }
                break;
            }
            acc.push(favorite);
          }

          return acc;
        }, []);

        return [...response.data, ...filteredFavorites].sort((a, b) => {
          const isFavoriteA = a.cp.cpIsFavorite;
          const isFavoriteB = b.cp.cpIsFavorite;

          if (isFavoriteA === isFavoriteB) {
            return 0;
          }
          if (isFavoriteA) {
            return -1;
          }
          return 1;
        });
      }),
    );
  }

  /* Project Time Delete */
  public deleteProjectTimeLogData(employeeId: number, billingId: number) {
    if (billingId) {
      const url = `${environment.url}${Routes.PROJECTTIMELOGEDITORDELETE}${billingId}`;
      return this.http.delete(url).pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error.error);
        }),
      );
    }
  }

  /* Project time add */
  public addProjectTimeLogData(employeeId: number, billingId: number, postJson: any) {
    if (employeeId && postJson) {
      const url = `${environment.url}${Routes.PROJECTTIMELOGADD}`;
      return this.http.post(url, postJson).pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error.error);
        }),
      );
    }
  }

  /* Project time update */
  public updateProjectTimeLogData(employeeId: number, billingId: number, postJson: any) {
    if (employeeId && billingId && postJson) {
      const url = `${environment.url}${Routes.PROJECTTIMELOGEDITORDELETE}${billingId}`;
      return this.http.put(url, postJson).pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error.error);
        }),
      );
    }
  }

  /* favorite unfavorite */
  public FavUnFavUpdate(employeeId: number, postJson: any, type: string) {
    if (type && postJson) {
      const url = `${environment.url}${Routes.FAVUNFAV}${type}`;
      return this.http.post(url, postJson).pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error.error);
        }),
      );
    }
  }

  /* Get My Projects */
  public getMyProjects(params) {
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    });
    return this.http.get(environment.url + Routes.MYPROJECT, { params, headers });
  }

  /* get getSettings */
  public getSettings() {
    return this.http.get(environment.url + Routes.GETSETTINGS);
  }

  /* / unsaved-changes */
  private unsavedChanges = false;

  public setUnsavedChanges(value: boolean) {
    this.unsavedChanges = value;
    this.storageService.setItem(StorageKeys.UNSAVEDCHANGES, value);
  }

  public getUnsavedChanges(): boolean {
    return this.unsavedChanges;
  }

  /* set unsaved item */
  private unsavedItems: Set<any> = new Set();
  public addUnsavedItem(item: any) {
    this.unsavedItems.add(item);
  }

  public removeUnsavedItem(item: any) {
    this.unsavedItems.delete(item);
  }

  public getUnsavedItems(): Set<any> {
    return this.unsavedItems;
  }

  public highlightUnsavedItems() {
    this.highlightUnsavedItemsEvent.emit();
  }

  public clearRedBorders() {
    this.clearRedBordersEvent.emit();
  }

  /* Project Time Log Get From CPID */
  public getProjectTimeLogDataFromCpid(cpIdsString) {
    const params = {
      cpIds: cpIdsString,
    };

    return this.http.get(environment.url + Routes.MYPROJECTCPID, { params }).pipe(
      tap(
        (response: any) => {
          response.entries.forEach((item) => {
            item.cp.cpNewAdded = true;
            item.billing = {
              billingId: 0,
              billingDate: '',
              billingCreated: '',
              billingQuantity: 0,
              billingIsBillable: item.cp.cpIsBillable,
              // billingIsBillable: false,
              billingExternalComment: '',
              billingInternalComment: '',
            };
          });
          return response.entries;
        },
        (error) => {},
      ),
    );
  }

  /* export  Time Sheets data */
  public exportTimeSheetsData(exportListForm: any, postJson: any, lang: string) {
    if (exportListForm.exportType === 'export_standard') {
      exportListForm.exportType = 'standard';
    }

    const headers = new HttpHeaders({
      'Accept-Language': lang,
    });

    const url = `${environment.url}${Routes.TIMESHEETSEXPORT}/${exportListForm.exportType}`;

    return this.http.post(url, postJson, { headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error.error);
      }),
    );
  }

  /* export Working Time Week data */
  public exportWorkingTimeWeekData(exportListForm: any, postJson: any, lang: string, type: string) {
    if (exportListForm.exportType === 'export_standard') {
      exportListForm.exportType = 'standard';
    }
    const headers = new HttpHeaders({
      'Accept-Language': lang,
    });
    const url = `${environment.url}${Routes.WORKINGTIMEEXPORT}/${type}/${exportListForm.exportType}`;
    return this.http.post(url, postJson, { headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error.error);
      }),
    );
  }

  /* export Working Time month data */
  public exportWorkingTimeMonthData(exportListForm: any, postJson: any, lang: string, type: string) {
    if (exportListForm.exportType === 'export_standard') {
      exportListForm.exportType = 'standard';
    }
    const headers = new HttpHeaders({
      'Accept-Language': lang,
    });
    const url = `${environment.url}${Routes.WORKINGTIMEEXPORT}/${type}/${exportListForm.exportType}`;
    return this.http.post(url, postJson, { headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error.error);
      }),
    );
  }

  /* export Project Time month data */
  public exportProjectTimeMonthData(exportListForm: any, postJson: any, lang: string, type: string) {
    if (exportListForm.exportType === 'export_standard') {
      exportListForm.exportType = 'standard';
    }
    const headers = new HttpHeaders({
      'Accept-Language': lang,
    });
    const url = `${environment.url}${Routes.PROJECTTIMEEXPORT}/${type}/${exportListForm.exportType}`;
    return this.http.post(url, postJson, { headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error.error);
      }),
    );
  }

  /* export Project Time week data */
  public exportProjectTimeWeekData(exportListForm: any, postJson: any, lang: string, type: string) {
    if (exportListForm.exportType === 'export_standard') {
      exportListForm.exportType = 'standard';
    }
    const headers = new HttpHeaders({
      'Accept-Language': lang,
    });
    const url = `${environment.url}${Routes.PROJECTTIMEEXPORT}/${type}/${exportListForm.exportType}`;
    return this.http.post(url, postJson, { headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error.error);
      }),
    );
  }

  public loadEmployeefilter(type): Observable<any> {
    const url = `${environment.url}${Routes.BASE}/${type}/weekview/selection/employees`;
    return this.http.get(url).pipe(
      map((response: any) => {
        return response.employees;
      }),
    );
  }

  public setTRSettings(data: string) {
    this.timeRecordingSettingsSubject.next(data);
  }

  public getTRSettings() {
    return this.timeRecordingSettingsSubject.asObservable();
  }

  public getTimerecordingSettings() {
    const url = `${environment.url}${Routes.BASE}/settings`;
    return this.http.get<any>(url).pipe(
      tap((response) => {
        if (response.data) {
          this.saveSettingToLS(response.data);
        }
      }),
    );
  }

  public saveSettingToLS(settings: any): void {
    this.storageService.setItem(StorageKeys.TIMERECORDING_SETTINGS, settings);
    this.settings.next(settings);
    this.setTRSettings(settings);
  }

  public loadEmployeeTimesheet(): Observable<any> {
    const url = `${environment.url}${Routes.BASE}/timesheets/selection/employees`;
    return this.http.get(url).pipe(
      map((response: any) => {
        const result = [];
        let totalEmployees = 0;

        for (const teamName of response.groups) {
          const lang = this.storageService.getItem(StorageKeys.LANG);
          let label = '';
          switch (lang) {
            case 'de':
              label = teamName.groupDescriptionDe || teamName.groupDescriptionEn || teamName.groupDescriptionFr;
              break;
            case 'fr':
              label = teamName.groupDescriptionFr || teamName.groupDescriptionEn || teamName.groupDescriptionDe;
              break;
            default:
              label = teamName.groupDescriptionEn || teamName.groupDescriptionDe || teamName.groupDescriptionFr;
              break;
          }

          result.push({
            value: teamName.id,
            label,
            queryType: 'team',
            groups: [],
          });
        }

        for (const teamName of response.employees) {
          result.push({
            value: teamName.id,
            label: teamName.lastName + ', ' + teamName.firstName,
            queryType: 'employee',
            groups: teamName.groups,
          });
        }

        if (response.employees) {
          totalEmployees = response.employees.length;
        }

        return { result, totalEmployees };
      }),
    );
  }

  public logWorkTime(
    form: UntypedFormGroup,
    stopwatchId: string = '',
    billingId: string = '',
    method: string = 'POST',
    employeeId: string,
    apeBrand?: string,
  ): Observable<any> {
    // Set the employeeId control value in the form
    form.value['employeeId'] = employeeId;
    if (apeBrand) {
      form.value['apeBrand'] = apeBrand;
    }

    if (method === 'POST') {
      const url: string =
        environment.url + Routes.DESKTOPPROJECTTIMELOG + (stopwatchId.length ? '?stopwatchId=' + stopwatchId : '');
      return this.http.post(url, form.value, this.httpOptions).pipe(
        tap(
          (response) => response,
          (error) => {
            // Handle error if needed
          },
        ),
      );
    } else {
      const url: string = environment.url + Routes.DESKTOPPROJECTTIMELOG + '/' + billingId;
      return this.http.put(url, form.value, this.httpOptions).pipe(
        tap(
          (response) => response,
          (error) => {
            // Handle error if needed
          },
        ),
      );
    }
  }

  pageloaded = false;
  public setPageLoaded(number): void {
    this.pageloaded = true;
  }

  public setPageLoadedFalse(): void {
    this.pageloaded = false;
  }

  public getPageLoaded(): boolean {
    return this.pageloaded;
  }

  public appendOrReplaceQueryParam(url: string, key: string, value: string): string {
    const urlParts = url.split('?');
    if (urlParts.length === 1) {
      return `${url}?${key}=${encodeURIComponent(value)}`;
    } else {
      const base = urlParts[0];
      const queryString = urlParts[1];

      const queryParams = new URLSearchParams(queryString);

      if (queryParams.has(key)) {
        queryParams.set(key, value);
      } else {
        queryParams.append(key, value);
      }
      return `${base}?${queryParams.toString()}`;
    }
  }

  public isValidTimeFormat(timeString: string): boolean {
    const timeFormatRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    return timeFormatRegex.test(timeString);
  }

  public cloneProjectDataSet() {
    this.storageService.setItem(StorageKeys.CLONE_PROJECT_DATA, {
      isCloneProject: false,
      sidebarLastcpId: 0,
      randomString: '',
      cpId: 0,
      billingId: '',
      billingIsBillable: false,
      billingQuantity: 10,
      billingQuantityTime: '00:00',
      billingExternalComment: '',
      billingInternalComment: '',
      billingDate: '',
      type: '',
      isCookieItem: false,
    });
  }

  public getStartAndEndOfWeekDate(year, weekNumber) {
    const startOfWeekDate = addDays(startOfWeek(new Date(year, 0, 4), { weekStartsOn: 1 }), (weekNumber - 1) * 7);
    const endOfWeekDate = addDays(startOfWeekDate, 6);

    return [format(startOfWeekDate, 'yyyy-MM-dd'), format(endOfWeekDate, 'yyyy-MM-dd')];
  }

  public translateWeekdays(data) {
    return this.translate.instant('Timerecording.day.' + data);
  }

  public canDeactivate(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.getUnsavedChanges()) {
        const confirmLeave = window.confirm(
          this.translate.instant('Booking.labels.doYouReallyWantToCloseWithoutSaving'),
        );
        if (confirmLeave) {
          this.setUnsavedChanges(false);
          this.clearRedBorders();
        } else {
          this.highlightUnsavedItems();
        }
        resolve(confirmLeave);
      } else {
        resolve(true);
      }
    });
  }

  public isTimingFormat() {
    const timerecordingSettings = this.storageService.getItem(StorageKeys.TIMERECORDING_SETTINGS);
    if (timerecordingSettings) {
      if (timerecordingSettings.settings.timeValuesRepresentation === 0) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  public setFormat(data) {
    const timingFormat = this.isTimingFormat();
    if (timingFormat) {
      const parts = data.toString().split(',');

      if (parts.length === 2) {
        const hours = parseInt(parts[0]);
        const minutes = parseInt(parts[1]);

        if (!isNaN(hours) && !isNaN(minutes)) {
          const adjustedMinutes = Math.round((minutes / 100) * 60);
          const formattedHours = hours < 10 ? `0${hours}` : hours;
          const formattedMinutes = adjustedMinutes < 10 ? `0${adjustedMinutes}` : adjustedMinutes;
          return `${formattedHours}:${formattedMinutes}`;
        }
      }
      return data;
    } else if (typeof data === 'number') {
      return data.toFixed(2).replace('.', ',');
    } else {
      const parts = data.split(':');

      if (parts.length === 2) {
        const hours = parseInt(parts[0]);
        const minutes = parseInt(parts[1]);

        if (!isNaN(hours) && !isNaN(minutes)) {
          const decimalMinutes = (hours * 60 + minutes) / 60;
          return (Math.round(decimalMinutes * 100) / 100).toFixed(2).replace('.', ',');
        }
      }
      return data;
    }
  }

  public getNonNegativeValue(value: number): number {
    if (value === null) {
      return value;
    }
    return Math.max(value, 0);
  }

  public checkIsEditable(date, type: string, settings?): boolean {
    const checkDate = date;
    const timerecordingSettings = settings || this.storageService.getItem(StorageKeys.TIMERECORDING_SETTINGS);
    if (timerecordingSettings) {
      const backwardVal =
        type === 'W'
          ? timerecordingSettings.settings?.backwardWorkingTimeChange
          : timerecordingSettings.settings?.backwardBillingsCollectionDays;

      const today = new Date();
      if (backwardVal === -1) {
        return true;
      } else if (backwardVal === 0) {
        const logDate = parseISO(checkDate);
        if (isSameDay(logDate, today)) {
          return true;
        }
      } else if (backwardVal >= 0) {
        const logDate = parseISO(checkDate);
        logDate.setHours(1, 0, 0, 0);
        const daysAgo = subDays(today, backwardVal);
        daysAgo.setHours(0, 0, 0, 0);
        if (isAfter(logDate, daysAgo) && isBefore(logDate, today)) {
          return true;
        }
      }
      return false;
    }
    return false;
  }

  public formatTime(value: number): string {
    const hour = Math.floor(value);
    const minute = Math.round((value - hour) * 60);
    return `${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}`;
  }

  public getFormattedDifference(debit, quantity, absenceQuantity = 0): string {
    const sumworkingtimeandabsenceQuantity = quantity === absenceQuantity ? quantity : quantity + absenceQuantity;
    const difference = debit - sumworkingtimeandabsenceQuantity;
    if (difference === 0) {
      return '0,00';
    } else {
      const formattedDifference = Math.abs(difference);
      return `${difference >= 0 ? '-' : '+'} ${this.setFormat(formattedDifference.toFixed(2).replace('.', ','))}`;
    }
  }

  public updateTextElement(canvasId: string, elementId: string, value: number, color: string, symbol: string) {
    const textElement = document.getElementById(canvasId + elementId);
    if (textElement) {
      if (value === Infinity) {
        textElement.textContent = '-';
      } else if (symbol === 'h') {
        textElement.textContent = this.setFormat(value.toFixed(2).replace('.', ',')) + ' ' + symbol;
      } else {
        textElement.textContent = value.toFixed(2).replace('.', ',') + ' ' + symbol;
      }
      if (color) {
        textElement.style.color = color.toString();
      }
    }
  }

  public convertToTime(dateTimeString: string): string {
    if (dateTimeString == null) {
      // return '00:00';
      return null;
    }
    const timeString = dateTimeString.split('T')[1];
    const hours = timeString.substring(0, 2);
    const minutes = timeString.substring(3, 5);
    return `${hours}:${minutes}`;
  }

  public dateModify(date: any) {
    if (!date) {
      return null;
    }
    if (typeof date === 'string' && date.includes('GMT')) {
      const dateArray = date.split(' ');
      const dateString = `${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${dateArray[4]} ${dateArray[5]}`;
      const parsedDate = new Date(dateString);
      return parsedDate;
    } else if (typeof date === 'string' && date.includes('/')) {
      const [month, year] = date.split('/');
      const parsedDate = new Date(`${month} 01, ${year} 00:00:00`);
      return parsedDate;
    } else {
      return date;
    }
  }

  public downloadFile(path: string): void {
    const href = environment.url + path;
    const tmpLink = document.createElement('a');
    const fileName = path.substr(path.lastIndexOf('/') + 1);
    tmpLink.href = href;
    tmpLink.download = fileName;
    tmpLink.target = '_blank';
    document.body.appendChild(tmpLink);
    tmpLink.click();
  }

  /* unsaved-changes inside popup */
  private popupUnsavedChanges = false;
  public popupsetUnsavedChanges(value: boolean) {
    this.popupUnsavedChanges = value;
  }
  public popupgetUnsavedChanges(): boolean {
    return this.popupUnsavedChanges;
  }

  /* relog */
  public relog(postJson: any) {
    const url = `${environment.url}${Routes.RELOG}`;
    return this.http.post(url, postJson).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error.error);
      }),
      tap(() => {
        this.reloading.next(true);
      }),
    );
  }

  public defaultRedirection(state) {
    const redirectState = {
      11: 'overview/day',
      12: 'overview/week',
      13: 'overview/month',
      21: 'working-time/day',
      22: 'working-time/week',
      23: 'working-time/month',
      31: 'project-time/day',
      32: 'project-time/week',
      33: 'project-time/month',
      41: 'time-sheets',
    };
    const timerecordingSettings = this.storageService.getItem(StorageKeys.TIMERECORDING_SETTINGS);
    /* if disable overview */
    if (
      (state === 11 || state === 12 || state === 13) &&
      timerecordingSettings.settings.page.disableTimerecordingOverview
    ) {
      state = 21;
    }
    /*check if disable WorkTime */
    if (
      (state === 21 || state === 22 || state === 23) &&
      timerecordingSettings.settings.page.disableTimerecordingWorkTime
    ) {
      state = 31;
    }
    /*check if disable Project Time */
    if (
      (state === 31 || state === 32 || state === 33) &&
      timerecordingSettings.settings.page.disableTimerecordingProjectTime
    ) {
      state = 11;
    }
    return redirectState[state] || redirectState[11];
  }

  public getAssignedAndUnassigned(userId, clientId) {
    const params = new HttpParams().set('clientId', clientId);
    const url = `${environment.url}${Routes.ASSIGNEDANDUNASSIGNED}${userId}`;

    return this.http.get(url, { params });
  }

  /* Project time update */
  public addAndremoveAssignProjectAPI(userId: number, type: string, postJson: any) {
    if (userId && type && postJson) {
      const url = `${environment.url}${Routes.ASSIGNEDANDUNASSIGNED}${type}/${userId}`;
      return this.http.put(url, postJson).pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error.error);
        }),
      );
    }
  }

  isHideBillableCheckboxInTimeRecording() {
    const timerecordingSettingsGet = this.storageService.getItem(StorageKeys.TIMERECORDING_SETTINGS);
    if (timerecordingSettingsGet) {
      if (timerecordingSettingsGet.settings.isHideBillableCheckboxInTimeRecording) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  isBillingInternalCommentVisibility() {
    const timerecordingSettingsGet = this.storageService.getItem(StorageKeys.TIMERECORDING_SETTINGS);
    if (timerecordingSettingsGet) {
      if (timerecordingSettingsGet.settings.isBillingInternalCommentVisibility) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  public getDeclinedHoursData(selectedId?: number | string): Observable<DeclinedHoursResponse> {
    let url = `${environment.url}${Routes.DECLINEDHOURS}`;
    if (selectedId) {
      url += `?employeeId=${selectedId}`;
    }
    return this.http.get<DeclinedHoursResponse>(url);
  }

  public getBoilerPlates(): Observable<IBoilerPlatesFormatted> {
    return this.http.get<IBoilerPlatesResponse>(`${environment.url}${Routes.BOILERPLATES}`).pipe(
      map((response) => {
        const formatted: IBoilerPlatesFormatted = {
          internalComment: [],
          externalComment: [],
        };

        const internalKeys = Object.keys(response.data?.bp_ident_time_recording_internal_comments ?? {}) || [];
        const externalKeys = Object.keys(response.data?.bp_ident_time_recording_external_comments ?? {}) || [];

        formatted.internalComment = internalKeys.map((key) => {
          return {
            active: true,
            label: key,
            value: response.data.bp_ident_time_recording_internal_comments[key],
          };
        });

        formatted.externalComment = externalKeys.map((key) => {
          return {
            active: true,
            label: key,
            value: response.data.bp_ident_time_recording_external_comments[key],
          };
        });

        return formatted;
      }),
    );
  }

  public spreadBilling(
    billingId: number,
    startTime: number,
    endTime: number,
    employeeId: number,
  ): Observable<ISpreadResponse> {
    const url = `${environment.url}${Routes.SPREAD}`;
    return this.http.post<ISpreadResponse>(url, {
      startTime,
      endTime,
      billingId,
      employeeId,
    });
  }

  public noramlizeWeekNumber(week: number): { weekNumber: number; year: number } {
    let weekNumber = week;
    let year = new Date().getFullYear();

    if (weekNumber < 0) {
      weekNumber = Math.abs(weekNumber);
      year -= 1;
    }

    return { weekNumber, year };
  }
}
