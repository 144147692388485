import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import { format, getISOWeek, isDate, parseISO, startOfDay } from 'date-fns';
import { Subscription } from 'rxjs';
import { StorageKeys } from '../../core/storage/storage.keys';
import { StorageService } from '../../core/storage/storage.service';
import { WorkTimeResponseInterface } from '../../modules/time-recording/interfaces/work-time-response.interface';
import { WorkTimeSummaryInterface } from '../../modules/time-recording/interfaces/work-time-summary-response.interface';
import { TrOverviewMonthModelComponent } from '../../modules/time-recording/shared/modal/tr-overview-month-model/tr-overview-month-model.component';
import { TrProjecttimeMonthModelComponent } from '../../modules/time-recording/shared/modal/tr-projecttime-month-model/tr-projecttime-month-model.component';
import { TrWorkingtimeMonthModelComponent } from '../../modules/time-recording/shared/modal/tr-workingtime-month-model/tr-workingtime-month-model.component';
import { ThemeService } from '../../modules/time-recording/theme.service';
import { TimeRecordingService } from '../../modules/time-recording/time-recording.service';
import { ModalService } from '../troi-modals/modal.service';
@Component({
  selector: 'troi-bar-chart',
  templateUrl: './troi-bar-chart.component.html',
  styleUrls: ['./troi-bar-chart.component.scss'],
})
export class TroiBarChartComponent implements OnInit, OnDestroy {
  private restoreParamsSubscription: Subscription;
  private weekFilterSubscription: Subscription;
  private reloadingSubscription: Subscription;
  @Input() chartCanvasId: string;
  @Input() width: string;
  @Input() height: string;

  viewChart: Chart;
  chartData: WorkTimeResponseInterface[];
  summaryData: WorkTimeSummaryInterface;
  viewChartData: Array<any> = [];
  monthViewData: Array<any> = [];
  filterDateBarChart: any = new Date();
  url: any;
  monthWeekData: any[] = [];
  weekWeekData: any[] = [];
  tooltipCallbackExecuted = false;
  userId: number;
  @Input() employeeId?: number;
  reloadListEmitter = new EventEmitter();
  public loading = false;

  barThickness = 20;
  x1FontSize = 9.1;

  public billableprogressbar = {
    percantage: 0,
    totalhours: 0,
  };

  public nonbillableprogressbar = {
    percantage: 0,
    totalhours: 0,
  };

  public workingTimeUndertimeWarningThresholdPercentage = 0;
  public workingTimeOvertimeWarningThresholdPercentage = 0;

  weeknumber = getISOWeek(new Date());
  timerecordingSettings: any;

  constructor(
    private storageService: StorageService,
    private http: HttpClient,
    public timeService: TimeRecordingService,
    private datePipe: DatePipe,
    public modalService: ModalService,
    private translate: TranslateService,
    private themeService: ThemeService,
  ) {
    /* reloading after add new */
    this.reloadingSubscription = this.timeService.reloading.subscribe((value) => {
      const storedFilterDate = this.storageService.getItem(StorageKeys.FILTERDATEBARCHART);
      if (storedFilterDate) {
        this.filterDateBarChart = isDate(storedFilterDate) ? storedFilterDate : parseISO(storedFilterDate);
      } else {
        this.filterDateBarChart = new Date();
      }
      this.getAllCharts();
    });

    this.onClick = this.onClick.bind(this);
    this.restoreParamsSubscription = this.timeService.restoreParamsBarChart.subscribe((response) => {
      if (response && response[1] !== undefined) {
        this.filterDateBarChart = format(new Date(response[1]), 'yyyy-MM-dd');
      }
      this.getAllCharts(response[0]);
    });

    /* weekfilter change */
    this.weekFilterSubscription = this.timeService.weekFilter.subscribe((value) => {
      if (value) {
        this.userId = value.employeeId;
        this.weeknumber = value.currentWeeknumber;
        this.getAllCharts('default');
      }
    });

    /* TimerecordingSettings  */
    this.timerecordingSettings = this.storageService.getItem(StorageKeys.TIMERECORDING_SETTINGS);
    if (this.timerecordingSettings) {
      if (this.timerecordingSettings.settings.warningThresholds.workingTimeUndertimeWarningThreshold) {
        this.workingTimeUndertimeWarningThresholdPercentage =
          this.timerecordingSettings.settings.warningThresholds.workingTimeUndertimeWarningThreshold;
      }
      if (this.timerecordingSettings.settings.warningThresholds.workingTimeOvertimeWarningThreshold) {
        this.workingTimeOvertimeWarningThresholdPercentage =
          this.timerecordingSettings.settings.warningThresholds.workingTimeOvertimeWarningThreshold;
      }
    }
  }

  ngOnInit(): void {
    this.weeknumber = getISOWeek(new Date());
    this.filterDateBarChart = startOfDay(new Date());

    if (this.employeeId) {
      this.userId = this.employeeId;
    } else {
      const user = this.storageService.getItem(StorageKeys.USER);
      if (user) {
        this.userId = user.id;
      }
    }
    this.getAllCharts('default');

    this.reloadListEmitter.subscribe(() => {
      if (!this.timeService.popupgetUnsavedChanges()) {
        return;
      }
      const storedFilterDate = this.storageService.getItem(StorageKeys.FILTERDATEBARCHART);

      if (storedFilterDate) {
        const dateValue: Date = isDate(storedFilterDate)
          ? startOfDay(storedFilterDate as unknown as Date)
          : startOfDay(new Date(storedFilterDate));

        this.filterDateBarChart = dateValue;
      } else {
        this.filterDateBarChart = startOfDay(new Date());
      }
      this.getAllCharts();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {}

  ngOnDestroy() {
    if (this.restoreParamsSubscription) {
      this.restoreParamsSubscription.unsubscribe();
    }
  }

  getAllCharts(data?: string) {
    this.storageService.setItem(StorageKeys.FILTERDATEBARCHART, this.filterDateBarChart);

    if (!this.userId) {
      return;
    }

    const { weekNumber, year: normalizedYear } = this.timeService.noramlizeWeekNumber(this.weeknumber);

    const currentDate = this.filterDateBarChart ? new Date(this.filterDateBarChart) : new Date();

    if (this.weeknumber < 0) {
      currentDate.setFullYear(normalizedYear);
    }

    this.filterDateBarChart = undefined;
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const day = 1;
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

    if (
      this.chartCanvasId === 'overViewMonthBarChart' ||
      this.chartCanvasId === 'workingTimeMonthBarChart' ||
      this.chartCanvasId === 'projectTimeMonthBarChart'
    ) {
      this.url = this.timeService.getMonthData(2, formattedDate, this.userId);
    } else if (
      this.chartCanvasId === 'overViewWeekBarChart' ||
      this.chartCanvasId === 'workingTimeWeekBarChart' ||
      this.chartCanvasId === 'projectTimeWeekBarChart'
    ) {
      this.url = this.timeService.getWeekData(1, this.userId, weekNumber, year);
    }
    if (this.url) {
      this.loading = true;
      this.url.subscribe((response: any) => {
        this.loading = false;
        if (response.length > 0) {
          this.summaryData = response[0].workingTimeSummary;

          /* Data */
          const workingTimeDays = response[0].workingTimeDays;
          this.chartData =
            workingTimeDays.length > 7
              ? workingTimeDays.filter((item) => new Date(item.date).getMonth() === month - 1)
              : workingTimeDays;
          this.SetData();

          /* ProgreeBar */
          this.billableprogressbar.percantage = this.summaryData.projectTime.billableToQuantityPercent;
          this.billableprogressbar.totalhours = this.summaryData.projectTime.billable;
          this.nonbillableprogressbar.percantage = this.summaryData.projectTime.notBillableToQuantityPercent;
          this.nonbillableprogressbar.totalhours = this.summaryData.projectTime.notBillable;
        }
      });
    }
  }

  SetData() {
    if (
      this.chartCanvasId === 'overViewMonthBarChart' ||
      this.chartCanvasId === 'workingTimeMonthBarChart' ||
      this.chartCanvasId === 'projectTimeMonthBarChart'
    ) {
      const filteredDataNew = [];
      const removeBlankEntry = [];
      this.chartData?.map((data) => {
        if (data.date !== '') {
          return removeBlankEntry.push(data);
        }
      });
      this.chartData = removeBlankEntry;
      this.chartData.forEach((item) => {
        filteredDataNew.push(item);
        if (new Date(item.date).getDay() === 0) {
          const newObject = {
            date: '',
            weekDay: null,
            workingTime: {
              debit: null,
              breakQuantity: null,
              absenceQuantity: null,
              quantity: null,
              percent: null,
            },
            projectTime: {
              debit: null,
              quantity: null,
              percent: null,
            },
          };
          filteredDataNew.push(newObject);
        }
      });
      this.chartData = filteredDataNew;
      this.showWeekWiseCalculationMonth();
      this.createChartView(this.chartCanvasId);
    } else {
      this.showWeekWiseCalculationWeek();
      this.createChartView(this.chartCanvasId);
    }
  }

  createChartView(canvasId: string) {
    const backgroundColor = this.themeService.getColor();
    const viewCtx = document.getElementById(canvasId) as HTMLCanvasElement;
    const chartExist = Chart.getChart(canvasId);
    if (chartExist !== undefined) chartExist.destroy();

    const viewLabels = this.chartData.map((item) => {
      if (item.date.length === 0) {
        return null;
      }

      const day = this.timeService.translateWeekdays(format(parseISO(item.date), 'eee').slice(0, 2));
      const date = format(parseISO(item.date), 'dd');
      const array = {
        day,
        date,
      };

      return item.date.length !== 0 ? array : null;
    });

    const viewChartTotalTimes = this.chartData.map((item) =>
      item.projectTime.debit === 0 ? 8 : item.projectTime.debit,
    );
    const workingQuantities = this.chartData.map((item) =>
      this.timeService.getNonNegativeValue(item.workingTime.quantity),
    );
    const absenceQuantities = this.chartData.map((item) =>
      this.timeService.getNonNegativeValue(item.workingTime.absenceQuantity),
    );
    const projectQuantities = this.chartData.map((item) =>
      this.timeService.getNonNegativeValue(item.projectTime.quantity),
    );
    const workingPercent = this.chartData.map((item) => this.timeService.getNonNegativeValue(item.workingTime.percent));
    const projectPercent = this.chartData.map((item) => this.timeService.getNonNegativeValue(item.projectTime.percent));

    /* Color Section */
    const BackgroundPercent =
      canvasId === 'projectTimeWeekBarChart' || canvasId === 'projectTimeMonthBarChart'
        ? projectPercent
        : workingPercent;
    const backgroundColors = BackgroundPercent.map((percent) => {
      if (percent == null) {
        return;
      } else if (percent <= this.workingTimeUndertimeWarningThresholdPercentage) {
        return 'rgba(245, 140, 1, 1)';
      } else if (percent >= this.workingTimeOvertimeWarningThresholdPercentage) {
        return this.themeService.getFullDarkColor_overview(backgroundColor);
      }
      return this.themeService.getDarkColor_overview(backgroundColor);
    });

    const backgroundColorsText = BackgroundPercent.map((percent) => {
      if (percent === null || percent === 0) {
        return this.themeService.getFullDarkColor_overview(backgroundColor);
      } else if (percent <= this.workingTimeUndertimeWarningThresholdPercentage) {
        return 'rgba(245, 140, 1, 1)';
      } else if (percent >= this.workingTimeOvertimeWarningThresholdPercentage) {
        return this.themeService.getFullDarkColor_overview(backgroundColor);
      }
      return this.themeService.getDarkColor_overview(backgroundColor);
    });

    const backgroundColorsForbarbelowText = BackgroundPercent.map((percent) => {
      if (percent === null || percent === 0) {
        return this.themeService.getColorProjectTime();
      } else if (percent <= this.workingTimeUndertimeWarningThresholdPercentage) {
        return 'rgba(245, 140, 1, 1)';
      } else if (percent >= this.workingTimeOvertimeWarningThresholdPercentage) {
        return this.themeService.getColorProjectTime();
      }
      return this.themeService.getColorProjectTime();
    });

    /* Utilization Calculation */
    let TotalUTZValue = 0;
    if (this.timerecordingSettings) {
      if (this.timerecordingSettings.settings.workingTimeUtilizationType === 0) {
        TotalUTZValue =
          (this.timeService.getNonNegativeValue(this.summaryData.projectTime.quantity) /
            this.timeService.getNonNegativeValue(this.summaryData.workingTime.debit)) *
          100;
      }
      if (this.timerecordingSettings.settings.workingTimeUtilizationType === 1) {
        TotalUTZValue =
          (this.timeService.getNonNegativeValue(this.summaryData.projectTime.quantity) /
            this.timeService.getNonNegativeValue(this.summaryData.workingTime.quantity)) *
          100;
      }
    }

    if (isNaN(TotalUTZValue)) {
      TotalUTZValue = 0;
    }

    /* ++++++++++ Set Total Hour Label ++++++++++++++ */
    const textElements = {
      TotalWT: {
        value: this.timeService.getNonNegativeValue(this.summaryData.workingTime.quantity),
        color: backgroundColorsText,
        symbol: 'h',
      },
      TotalPT: {
        value: this.timeService.getNonNegativeValue(this.summaryData.projectTime.quantity),
        color: backgroundColor,
        symbol: 'h',
      },
      TotalUTZ: {
        value: TotalUTZValue,
        color: null,
        symbol: '%',
      },
      TotalABS: {
        value: this.timeService.getNonNegativeValue(this.summaryData.workingTime.absenceQuantity),
        color: null,
        symbol: 'h',
      },
      TotalBVsNb: {
        value: this.timeService.getNonNegativeValue(this.summaryData.projectTime.billableVsNotBillablePercent),
        color: null,
        symbol: '%',
      },
    };

    for (const key in textElements) {
      if (textElements.hasOwnProperty(key)) {
        this.timeService.updateTextElement(
          canvasId,
          key,
          textElements[key].value,
          textElements[key].color,
          textElements[key].symbol,
        );
      }
    }

    this.viewChartData = [];

    if (canvasId !== 'projectTimeWeekBarChart' && canvasId !== 'projectTimeMonthBarChart') {
      const workTimeDataset = {
        label: 'Working Time',
        data: this.chartData.map((item) => {
          const quantity = this.timeService.getNonNegativeValue(item.workingTime.quantity);
          return quantity > 8 ? 8 : quantity;
        }),
        borderColor: backgroundColors,
        borderWidth: 1,
        stack: 'stack',
        backgroundColor: backgroundColors,
        order: 2,
        barThickness: this.barThickness,
      };
      this.viewChartData.push(workTimeDataset);
    }

    if (canvasId !== 'workingTimeWeekBarChart' && canvasId !== 'workingTimeMonthBarChart') {
      const projectTimeDataset = {
        label: 'Project Time',
        data: this.chartData.map((item) => {
          const quantity = item.projectTime.quantity;
          return quantity > 8 ? 8 : quantity;
        }),
        borderColor: this.themeService.getColorProjectTime(),
        borderWidth: 1,
        stack: 'stack',
        backgroundColor: this.themeService.getColorProjectTime(),
        order: 3,
        barThickness: this.barThickness,
      };
      this.viewChartData.push(projectTimeDataset);
    }

    const overViewDataset = {
      label: '',
      data: viewChartTotalTimes,
      borderColor: this.themeService.getLightColor(backgroundColor),
      borderWidth: 1,
      stack: 'stack',
      order: 2,
      backgroundColor: this.themeService.getLightColor(backgroundColor),
      barThickness: this.barThickness,
    };

    this.viewChartData.push(overViewDataset);

    this.viewChart = new Chart(viewCtx, {
      type: 'bar',
      data: {
        labels: viewLabels.map((e) => this.splitLabelForChart(e)),
        datasets: this.viewChartData,
      },
      options: {
        // responsive: true,
        events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
        maintainAspectRatio: false,
        interaction: {
          mode: 'index',
        },
        scales: {
          x: {
            display: true,
            stacked: true,
            position: 'top',
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 13.5,
                weight: '800',
                family: "'Troi-Regular', Fallback, sans-serif",
              },
              // color: '#4D4F5C',
              color: '#000000',
            },
            border: {
              display: false,
            },
          },
          x1: {
            display: true,
            stacked: true,
            position: 'bottom',
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
            ticks: {
              font: {
                weight: '900',
                // size: 9.1,
                size: 11,
                // size: this.x1FontSize,
                family: "'Troi-Regular', Fallback, sans-serif",
              },
              callback: (index: number) => {
                const number =
                  canvasId === 'projectTimeWeekBarChart' || canvasId === 'projectTimeMonthBarChart'
                    ? projectQuantities[index]
                    : workingQuantities[index];
                if (number != null) {
                  const formattedNumber = number.toFixed(2);
                  const finalFormattedNumber = this.timeService.setFormat(formattedNumber.replace('.', ','));
                  return finalFormattedNumber;
                } else {
                  return '';
                }
              },
              color:
                canvasId === 'projectTimeWeekBarChart' || canvasId === 'projectTimeMonthBarChart'
                  ? backgroundColor
                  : backgroundColorsForbarbelowText,
            },
          },
          y: {
            display: false,
            stacked: false,
            beginAtZero: true,
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            padding: 15,
            cornerRadius: 2,
            borderWidth: 0.2,
            borderColor: '#1B1610',
            backgroundColor: '#FFFFFF',
            bodyColor: backgroundColor,
            yAlign: 'bottom',
            bodyFont: {
              size: 12,
              weight: 'normal',
              family: "'Troi-Regular', Fallback, sans-serif",
            },
            filter: (tooltipItem) => {
              return tooltipItem.dataset.label !== '';
            },
            callbacks: {
              title: () => {
                return null;
              },
              label: (context) => {
                if (context.dataset.label === 'Working Time') {
                  /* working */
                  const workingQuantity = workingQuantities[context.dataIndex];
                  const formattedWorkingQuantity = workingQuantity.toFixed(2);
                  const finalFormattedWorkingQuantity = this.timeService.setFormat(
                    formattedWorkingQuantity.replace('.', ','),
                  );

                  /* absenceQuantities */
                  const absenceQuantity = absenceQuantities[context.dataIndex];
                  const formattedAbsenceQuantity = absenceQuantity.toFixed(2);
                  const finalFormattedAbsenceQuantity = this.timeService.setFormat(
                    formattedAbsenceQuantity.replace('.', ','),
                  );

                  return [
                    this.translate.instant('Timerecording.WorkingTime') + ' : ' + finalFormattedWorkingQuantity + ' h',
                    this.translate.instant('Timerecording.absenceQuantity') +
                      ' : ' +
                      finalFormattedAbsenceQuantity +
                      ' h',
                  ];
                }
                if (context.dataset.label === 'Project Time') {
                  const PQuantity = projectQuantities[context.dataIndex];
                  const formattedPQuantity = PQuantity.toFixed(2);
                  const finalFormattedPQuantity = this.timeService.setFormat(formattedPQuantity.replace('.', ','));
                  return [this.translate.instant('Timerecording.ProjectTime') + ' : ' + finalFormattedPQuantity + ' h'];
                }
                return null;
              },
              labelTextColor: (context) => {
                return backgroundColor;
              },
            },
            displayColors: false,
          },
        },
        onClick: this.onClick,
      },
    });
  }

  splitLabelForChart(label) {
    if (label != null) {
      const lines = [label.day, label.date];
      return lines;
    }
  }

  showWeekWiseCalculationWeek() {
    const weekNumber = getISOWeek(new Date(this.chartData[0].date));
    const workingTimeTotalDebit = this.chartData.reduce(
      (sum, item) => sum + this.timeService.getNonNegativeValue(item.workingTime.debit),
      0,
    );
    const workingTimeTotalAbsenceQuantity = this.chartData.reduce(
      (sum, item) => sum + this.timeService.getNonNegativeValue(item.workingTime.absenceQuantity),
      0,
    );
    const workingTimeweekTotal = this.chartData.reduce(
      (sum, item) => sum + this.timeService.getNonNegativeValue(item.workingTime.quantity),
      0,
    );
    const projectTimeweekTotal = this.chartData.reduce(
      (sum, item) => sum + this.timeService.getNonNegativeValue(item.projectTime.quantity),
      0,
    );
    const colorWt =
      workingTimeweekTotal <= workingTimeTotalDebit / 2
        ? 'rgba(245, 140, 1, 1)'
        : this.themeService.getFullDarkColor_overview(this.themeService.getColor());

    this.weekWeekData = [
      {
        weekNumber,
        workingTimeTotalDebit,
        workingTimeTotalAbsenceQuantity,
        workingTimeweekTotal,
        projectTimeweekTotal,
        colorWt,
      },
    ];
  }

  showWeekWiseCalculationMonth() {
    const WeekData = [];
    let pushdata = [];
    this.chartData.map((chartDate) => {
      if (chartDate.date !== '') {
        if (pushdata.length === 0) {
          WeekData.push(pushdata);
        }
        pushdata.push(chartDate);
      } else {
        pushdata = [];
      }
    });

    this.monthWeekData = WeekData.map((week) => {
      const weekNumber = getISOWeek(new Date(week[0].date));
      const workingTimeTotalDebit = week.reduce((totaldT, data) => totaldT + data['workingTime']['debit'], 0);
      const workingTimeweekTotal = week.reduce((totalwT, data) => totalwT + data['workingTime']['quantity'], 0);
      const projectTimeweekTotal = week.reduce((totalpT, data) => totalpT + data['projectTime']['quantity'], 0);

      const colorWt =
        workingTimeTotalDebit > 0
          ? workingTimeweekTotal <= workingTimeTotalDebit / 2
            ? 'rgba(245, 140, 1, 1)'
            : this.themeService.getFullDarkColor_overview(this.themeService.getColor())
          : this.themeService.getFullDarkColor_overview(this.themeService.getColor());

      return { weekNumber, workingTimeTotalDebit, workingTimeweekTotal, projectTimeweekTotal, colorWt, week };
    });
  }

  private onClick(event, chartElement) {
    if (chartElement && chartElement.length > 0) {
      const dataIndex = chartElement[0].index;
      if (!this.tooltipCallbackExecuted) {
        this.tooltipCallbackExecuted = true;
        const dates = this.chartData.map((item) => {
          if (item.date.length === 0) {
            return null;
          }
          return format(parseISO(item.date), 'yyyy-MM-dd');
        });
        /* Show Model */
        this.showPopup(dates[dataIndex]);
      }
    }
  }

  /* Show Popup */
  showPopup(date): void {
    this.modalService.object = {
      date,
    };
    this.timeService.popupsetUnsavedChanges(false);
    if (this.chartCanvasId === 'overViewMonthBarChart') {
      this.modalService.init(TrOverviewMonthModelComponent, {}, { reloadList: this.reloadListEmitter });
    } else if (this.chartCanvasId === 'workingTimeMonthBarChart') {
      this.modalService.init(TrWorkingtimeMonthModelComponent, {}, { reloadList: this.reloadListEmitter });
    } else if (this.chartCanvasId === 'projectTimeMonthBarChart') {
      this.modalService.init(
        TrProjecttimeMonthModelComponent,
        {},
        { reloadList: this.reloadListEmitter },
        undefined,
        '90%',
        undefined,
        '90%',
      );
    }

    const chartCanvas = document.getElementById(this.chartCanvasId) as HTMLCanvasElement;
    chartCanvas.addEventListener('click', () => {
      this.tooltipCallbackExecuted = false;
    });
  }

  /* Progress bar */
  progressbar() {
    this.billableprogressbar.percantage = this.summaryData.projectTime.billableToQuantityPercent;
    this.billableprogressbar.totalhours = this.summaryData.projectTime.billable;

    this.nonbillableprogressbar.percantage = this.summaryData.projectTime.notBillableToQuantityPercent;
    this.nonbillableprogressbar.totalhours = this.summaryData.projectTime.notBillable;
  }
}
